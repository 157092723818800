import cloverBack from './clovers/images/clover-back.png'
import cloverGreen1 from './clovers/images/clover-green-1.png'
import cloverGreen10 from './clovers/images/clover-green-10.png'
import cloverGreen11 from './clovers/images/clover-green-11.png'
import cloverGreen12 from './clovers/images/clover-green-12.png'
import cloverGreen13 from './clovers/images/clover-green-13.png'
import cloverGreen14 from './clovers/images/clover-green-14.png'
import cloverGreen15 from './clovers/images/clover-green-15.png'
import cloverGreen16 from './clovers/images/clover-green-16.png'
import cloverGreen17 from './clovers/images/clover-green-17.png'
import cloverGreen18 from './clovers/images/clover-green-18.png'
import cloverGreen19 from './clovers/images/clover-green-19.png'
import cloverGreen2 from './clovers/images/clover-green-2.png'
import cloverGreen20 from './clovers/images/clover-green-20.png'
import cloverGreen3 from './clovers/images/clover-green-3.png'
import cloverGreen4 from './clovers/images/clover-green-4.png'
import cloverGreen5 from './clovers/images/clover-green-5.png'
import cloverGreen6 from './clovers/images/clover-green-6.png'
import cloverGreen7 from './clovers/images/clover-green-7.png'
import cloverGreen8 from './clovers/images/clover-green-8.png'
import cloverGreen9 from './clovers/images/clover-green-9.png'
import cloverPurple1 from './clovers/images/clover-purple-1.png'
import cloverPurple10 from './clovers/images/clover-purple-10.png'
import cloverPurple11 from './clovers/images/clover-purple-11.png'
import cloverPurple12 from './clovers/images/clover-purple-12.png'
import cloverPurple13 from './clovers/images/clover-purple-13.png'
import cloverPurple14 from './clovers/images/clover-purple-14.png'
import cloverPurple15 from './clovers/images/clover-purple-15.png'
import cloverPurple16 from './clovers/images/clover-purple-16.png'
import cloverPurple17 from './clovers/images/clover-purple-17.png'
import cloverPurple18 from './clovers/images/clover-purple-18.png'
import cloverPurple19 from './clovers/images/clover-purple-19.png'
import cloverPurple2 from './clovers/images/clover-purple-2.png'
import cloverPurple20 from './clovers/images/clover-purple-20.png'
import cloverPurple3 from './clovers/images/clover-purple-3.png'
import cloverPurple4 from './clovers/images/clover-purple-4.png'
import cloverPurple5 from './clovers/images/clover-purple-5.png'
import cloverPurple6 from './clovers/images/clover-purple-6.png'
import cloverPurple7 from './clovers/images/clover-purple-7.png'
import cloverPurple8 from './clovers/images/clover-purple-8.png'
import cloverPurple9 from './clovers/images/clover-purple-9.png'
import cloverRed1 from './clovers/images/clover-red-1.png'
import cloverRed10 from './clovers/images/clover-red-10.png'
import cloverRed11 from './clovers/images/clover-red-11.png'
import cloverRed12 from './clovers/images/clover-red-12.png'
import cloverRed13 from './clovers/images/clover-red-13.png'
import cloverRed14 from './clovers/images/clover-red-14.png'
import cloverRed15 from './clovers/images/clover-red-15.png'
import cloverRed16 from './clovers/images/clover-red-16.png'
import cloverRed17 from './clovers/images/clover-red-17.png'
import cloverRed18 from './clovers/images/clover-red-18.png'
import cloverRed19 from './clovers/images/clover-red-19.png'
import cloverRed2 from './clovers/images/clover-red-2.png'
import cloverRed20 from './clovers/images/clover-red-20.png'
import cloverRed3 from './clovers/images/clover-red-3.png'
import cloverRed4 from './clovers/images/clover-red-4.png'
import cloverRed5 from './clovers/images/clover-red-5.png'
import cloverRed6 from './clovers/images/clover-red-6.png'
import cloverRed7 from './clovers/images/clover-red-7.png'
import cloverRed8 from './clovers/images/clover-red-8.png'
import cloverRed9 from './clovers/images/clover-red-9.png'
import cloverYellow1 from './clovers/images/clover-yellow-1.png'
import cloverYellow10 from './clovers/images/clover-yellow-10.png'
import cloverYellow11 from './clovers/images/clover-yellow-11.png'
import cloverYellow12 from './clovers/images/clover-yellow-12.png'
import cloverYellow13 from './clovers/images/clover-yellow-13.png'
import cloverYellow14 from './clovers/images/clover-yellow-14.png'
import cloverYellow15 from './clovers/images/clover-yellow-15.png'
import cloverYellow16 from './clovers/images/clover-yellow-16.png'
import cloverYellow17 from './clovers/images/clover-yellow-17.png'
import cloverYellow18 from './clovers/images/clover-yellow-18.png'
import cloverYellow19 from './clovers/images/clover-yellow-19.png'
import cloverYellow2 from './clovers/images/clover-yellow-2.png'
import cloverYellow20 from './clovers/images/clover-yellow-20.png'
import cloverYellow3 from './clovers/images/clover-yellow-3.png'
import cloverYellow4 from './clovers/images/clover-yellow-4.png'
import cloverYellow5 from './clovers/images/clover-yellow-5.png'
import cloverYellow6 from './clovers/images/clover-yellow-6.png'
import cloverYellow7 from './clovers/images/clover-yellow-7.png'
import cloverYellow8 from './clovers/images/clover-yellow-8.png'
import cloverYellow9 from './clovers/images/clover-yellow-9.png'
import board from './players/board.jpg'
import shinyBG from './clovers/images/sparkles.gif'
import ladybug from './players/Ladybug.png';

const Images = {
  board, cloverBack,
  cloverYellow1, cloverYellow2, cloverYellow3, cloverYellow4, cloverYellow5, cloverYellow6, cloverYellow7, cloverYellow8, cloverYellow9, cloverYellow10,
  cloverYellow11, cloverYellow12, cloverYellow13, cloverYellow14, cloverYellow15, cloverYellow16, cloverYellow17, cloverYellow18, cloverYellow19,
  cloverYellow20,
  cloverRed1, cloverRed2, cloverRed3, cloverRed4, cloverRed5, cloverRed6, cloverRed7, cloverRed8, cloverRed9, cloverRed10,
  cloverRed11, cloverRed12, cloverRed13, cloverRed14, cloverRed15, cloverRed16, cloverRed17, cloverRed18, cloverRed19, cloverRed20,
  cloverPurple1, cloverPurple2, cloverPurple3, cloverPurple4, cloverPurple5, cloverPurple6, cloverPurple7, cloverPurple8, cloverPurple9, cloverPurple10,
  cloverPurple11, cloverPurple12, cloverPurple13, cloverPurple14, cloverPurple15, cloverPurple16, cloverPurple17, cloverPurple18, cloverPurple19,
  cloverPurple20,
  cloverGreen1, cloverGreen2, cloverGreen3, cloverGreen4, cloverGreen5, cloverGreen6, cloverGreen7, cloverGreen8, cloverGreen9, cloverGreen10,
  cloverGreen11, cloverGreen12, cloverGreen13, cloverGreen14, cloverGreen15, cloverGreen16, cloverGreen17, cloverGreen18, cloverGreen19, cloverGreen20,
  shinyBG,
  ladybug
}

export default Images